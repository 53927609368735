import { useState } from "react";
import { Config } from "../../domain/models";
import { Alert, Money } from "../../utils";
import { RestConfigRepository } from "../../infrastructure/repositories";
import { ConfigRepository } from "../../domain/repositories";
import { Decimal, InputValidator, MoneyValue, Number, Text } from "../../infrastructure/inputs";

const useConfig = () => {
  const [loading, setLoading] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [config, setConfig] = useState<Config | undefined>(undefined);
  const [minAmount, setMinAmount] = useState(MoneyValue.dirty(''));
  const [maxAmount, setMaxAmount] = useState(MoneyValue.dirty(''));
  const [minDays, setMinDays] = useState(Number.dirty(''));
  const [maxDays, setMaxDays] = useState(Number.dirty(''));
  const [interestRate, setInterestRate] = useState(Decimal.dirty(''));
  const [onlineFeeRate, setOnlineFeeRate] = useState(Decimal.dirty(''));
  const [contactEmail, setContactEmail] = useState(Text.dirty(''));
  const [contactPhone, setContactPhone] = useState(Text.dirty(''));
  const [contactAddress, setContactAddress] = useState(Text.dirty(''));
  const [onlineSignatureFee, setOnlineSignatureFee] = useState(MoneyValue.dirty(''));
  const [creditScoreConsultationFee, setCreditScoreConsultationFee] = useState(MoneyValue.dirty(''));
  const [maintenanceFee, setMaintenanceFee] = useState(MoneyValue.dirty(''));

  const configRepository: ConfigRepository = RestConfigRepository.getInstance();

  const loadConfig = async () => {
    setLoading(true);
    const [config, errorMessage] = await configRepository.getDefaultConfig();
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setConfig(config);
      loadInitialData(config!);
    }
  }

  const loadInitialData = (config: Config) => {
    setMinAmount(MoneyValue.dirty(config.minAmount.toString()));
    setMaxAmount(MoneyValue.dirty(config.maxAmount.toString()));
    setMinDays(Number.dirty(config.minDays.toString()));
    setMaxDays(Number.dirty(config.maxDays.toString()));
    setInterestRate(Decimal.dirty(((config.defaultAnnualEffectiveInterestRate * 100.0).toFixed(2)).toString()));
    setOnlineFeeRate(Decimal.dirty((config.onlinePaymentFeeRate * 100.0).toString()));
    setContactEmail(Text.dirty(config.contactEmail));
    setContactPhone(Text.dirty(config.contactPhone));
    setContactAddress(Text.dirty(config.contactAddress));
    setOnlineSignatureFee(MoneyValue.dirty(config.onlineSignatureFee.toString()));
    setCreditScoreConsultationFee(MoneyValue.dirty(config.creditScoreConsultationFee.toString()));
    setMaintenanceFee(MoneyValue.dirty(config.maintenanceFee.toString()));
  }

  const updateConfig = async () => {
    setSubmitted(true);
    if (!validForm()) return;

    setLoading(true);
    const [config, errorMessage] = await configRepository.updateDefaultConfig({
      min_amount: minAmount.getNumber()!,
      max_amount: maxAmount.getNumber()!,
      min_days: minDays.getNumber()!,
      max_days: maxDays.getNumber()!,
      default_annual_effective_interest_rate: globalThis.Number((interestRate.getNumber()! / 100).toFixed(5)),
      online_payment_fee_rate: onlineFeeRate.getNumber()! / 100.0,
      contact_email: contactEmail.value,
      contact_phone: contactPhone.value,
      contact_address: contactAddress.value,
      online_signature_fee: Money.parseFromCOP(onlineSignatureFee.getMoney()!),
      credit_score_consultation_fee: Money.parseFromCOP(creditScoreConsultationFee.getMoney()!),
      maintenance_fee: Money.parseFromCOP(maintenanceFee.getMoney()!)
    });
    setLoading(false);

    if (errorMessage) {
      Alert.showError(errorMessage);
    } else {
      setConfig(config);
      loadInitialData(config!);
      Alert.showSuccess('Configuración actualizada correctamente');
    }
  }

  const validForm = (): boolean => {
    return InputValidator.valid([
      minAmount, maxAmount,
      minDays, maxDays,
      interestRate, onlineFeeRate,
      contactEmail, contactPhone,
      contactAddress, onlineSignatureFee,
      creditScoreConsultationFee, maintenanceFee
    ]);
  }

  return {
    loading, setLoading,
    config, updateConfig,
    minAmount, setMinAmount,
    maxAmount, setMaxAmount,
    minDays, setMinDays,
    maxDays, setMaxDays,
    interestRate, setInterestRate,
    onlineFeeRate, setOnlineFeeRate,
    loadConfig, submitted,
    contactEmail, setContactEmail,
    contactPhone, setContactPhone,
    contactAddress, setContactAddress,
    onlineSignatureFee, setOnlineSignatureFee,
    creditScoreConsultationFee, setCreditScoreConsultationFee,
    maintenanceFee, setMaintenanceFee
  };
};

export default useConfig;