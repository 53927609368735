import { EventEmail, } from "../../../../domain/models";
import { EventEmailType } from "../../../../domain/models/event_email";
import { EventEmailEntity } from "../entities";

export default class EventEmailMapper {
  static toModel(entity: EventEmailEntity): EventEmail {
    return new EventEmail({
      id: entity.id,
      createdAt: entity.created_at,
      eventType: EventEmailMapper.eventCdToEventType(entity.event_cd),
      subject: entity.subject,
      body: entity.body,
      smsMessage: entity.sms_message,
    })
  }

  static eventCdToEventType = (eventCd: number): EventEmailType => {
    switch (eventCd) {
      case 0:
        return EventEmailType.loan_overdue;
      case 1:
        return EventEmailType.payment_date;
      case 2:
        return EventEmailType.near_payment_date;
      case 3:
        return EventEmailType.reset_password;
      case 4:
        return EventEmailType.contract_signed_received;
      case 5:
        return EventEmailType.legacy_users_onboarding;
      default:
        throw new Error(`Unknown status: ${eventCd}`);
    }
  }
}