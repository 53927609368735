import axios, { AxiosError } from "axios";
import { Constants, Environment, JsonUtil } from "../../../utils";
import { EventEmail, PreviewedEmail } from "../../../domain/models";
import { EventEmailMapper, PreviewedEmailMapper } from "./mappers";
import { EventEmailsRepository } from "../../../domain/repositories";
import { EventEmailEntity, PreviewedEmailEntity } from "./entities";
import { LocalStorageTokenRepository } from "..";

export default class RestEventEmailsRepository implements EventEmailsRepository {
  private static instance: RestEventEmailsRepository;

  private constructor() { }

  static getInstance() {
    if (!this.instance) {
      this.instance = new RestEventEmailsRepository();
    }
    return this.instance;
  }

  async getEmails(): Promise<[EventEmail[]?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<EventEmailEntity[]>(`${Environment.backendUrl}/admin/v1/event_emails?t=${token}`);
      const emails = response.data.map(EventEmailMapper.toModel);

      return [emails, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async getEmail(id: string): Promise<[EventEmail?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<EventEmailEntity>(`${Environment.backendUrl}/admin/v1/event_emails/${id}?t=${token}`);
      const email = EventEmailMapper.toModel(response.data);

      return [email, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async updateEmail(id: string, subject: string, body: string, smsMessage?: string): Promise<[EventEmail?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.put<EventEmailEntity>(`${Environment.backendUrl}/admin/v1/event_emails/${id}?t=${token}`, {
        subject,
        body,
        sms_message: smsMessage,
      });
      const email = EventEmailMapper.toModel(response.data);

      return [email, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }

  async previewEmail(id: string): Promise<[PreviewedEmail?, string?]> {
    try {
      const token = LocalStorageTokenRepository.getInstance().getToken();

      const response = await axios.get<PreviewedEmailEntity>(`${Environment.backendUrl}/admin/v1/event_emails/${id}/preview?t=${token}`);
      const preview = PreviewedEmailMapper.toModel(response.data);

      return [preview, undefined];
    } catch (error) {
      let mssg = Constants.GENERIC_ERROR_MSSG;
      if (error instanceof AxiosError) {
        mssg = JsonUtil.mssgFromError(error);
      }
      if (Environment.env === 'development') { console.log(error); }
      return [undefined, mssg];
    }
  }
}